import {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

function useAxiosInstance() {
    const navigate = useNavigate();
    const [axiosInstance] = useState(() => {
        const instance = axios.create({
            baseURL: "https://deployment.trak-4.com/api/",
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        instance.interceptors.request.use((config) => {
            const token = `Bearer ${localStorage.getItem("BOM_access_token")}`;
            if (token) {
                config.headers.Authorization = token;
            }
            return config;
        });

        instance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                    if (error.response.status === 401) {
                        localStorage.clear();
                        navigate("/");
                    }
                } else if (error.request) {
                    console.error("No response received from the server:", error.request);
                } else {
                    console.error("Error in making the request:", error.message);
                }
                return Promise.reject(error);
            }
        );
        return instance;
    });

    return axiosInstance;
}

export default useAxiosInstance;