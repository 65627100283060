import Home from '../DashBoard/Home'
import {Navigate, useLocation} from "react-router-dom";

const RequireRole = ({roles, Element}) => {
    let role = 'superAdmin'
    const location = useLocation();
    return (
        roles.includes(role) ? Element : <Navigate to="/" state={{from: location}}/>
    );
};

const routes = [
    {
        id: 1,
        path: "/home",
        name: "Home",
        element: <RequireRole roles={['superAdmin', "user", 'admin']} Element={<Home/>}/>
    }
]

export default routes;