import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Login from "../Authentication/Login";
import ProtectedRoutes from "./ProtectedRoutes";
import routes from './routes'

export default function MainRouter() {

    const LoginRequirement = () => {
        return (
            localStorage.getItem("loggedIn") ? <Navigate to='/home'/> : <Login/>
        );
    };

    const renderProtectedRoutes = () => {
        return (
            <Route element={<ProtectedRoutes/>}>
                {routes.map((route) => {
                    return (
                        <Route
                            key={route.id}
                            path={route.path}
                            name={route.name}
                            element={route.element}
                        />)
                })}
            </Route>)
    }
    return (
        <Routes>
            {renderProtectedRoutes()}
            <Route path="/" element={<LoginRequirement/>}/>
        </Routes>
    )
}