import React, {useEffect, useState} from 'react'
import './home.css'
import {Button, styled} from "@mui/material";
import useAxiosInstance from "../useAxiosInstance";
import FormData from "form-data";
import Swal from "sweetalert2";
import Spinner from "react-spinners/BeatLoader";
import {useNavigate} from "react-router-dom";

function Home() {
    const instance = useAxiosInstance();
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    });

    const [data, setData] = useState({clientFile: "", serverFile: ""});
    const [response, setResponse] = useState({clientResponse: [], serverResponse: []});
    const [IsSpinner, setIsSpinner] = useState(false);

    const changeHandler = (event) => {
        const {name} = event.target;
        setData({
            ...data,
            [name]: event.target.files[0]
        });
    };
    const uploadClientSideFile = () => {
        setResponse(prevState => ({...prevState, clientResponse: []}));

        const File = new FormData();
        File.append("fileToUpload", data.clientFile);

        instance.post(`StagingClientSideDeployment`, File)
            .then((response) => {
                if (response.data.success) {
                    setResponse(prevState => ({...prevState, clientResponse: response.data.data}));
                    Toast.fire({
                        icon: "success",
                        title: "Uploaded successfully"
                    });
                    setData(prevState => ({...prevState, clientFile: ""}));
                } else {
                    setData(prevState => ({...prevState, clientFile: ""}));
                    Swal.fire(response.data.message ? response.data.message : "");
                }
                setIsSpinner(false);
            })
            .catch(() => {
                setData(prevState => ({...prevState, clientFile: ""}));
                setIsSpinner(false);
                Swal.fire("Uploading failed. Please try again.");
            });
    };
    const uploadServerSideFile = () => {
        setResponse(prevState => ({...prevState, serverResponse: []}));

        const File = new FormData();
        File.append("fileToUpload", data.serverFile);

        instance.post(`StagingServerSideDeployment`, File)
            .then((response) => {
                if (response.data.success) {
                    setResponse(prevState => ({...prevState, serverResponse: response.data.data}));
                    Toast.fire({
                        icon: "success",
                        title: "Uploaded successfully"
                    });
                    setData(prevState => ({...prevState, serverFile: ""}));
                } else {
                    setData(prevState => ({...prevState, serverFile: ""}));
                    Swal.fire(response.data.message ? response.data.message : "");
                }
                setIsSpinner(false);
            })
            .catch(() => {
                setData(prevState => ({...prevState, serverFile: ""}));
                setIsSpinner(false);
                Swal.fire("Uploading failed. Please try again.");
            });
    };

    useEffect(() => {
        if (data.clientFile) {
            if (data.clientFile.name.split('.')[1] === "zip") {
                uploadClientSideFile();
                setIsSpinner(true);
            } else {
                Swal.fire("Please select a zip file.");
            }
        }
        if (data.serverFile) {
            if (data.serverFile.name.split('.')[1] === "zip") {
                uploadServerSideFile();
                setIsSpinner(true);
            } else {
                Swal.fire("Please select a zip file.");
            }
        }
    }, [data]);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <>
            <div className="mt-3 text-end me-3">
                <Button onClick={() => {
                    localStorage.clear();
                    navigate("/");
                }} variant="contained" className="text-bg-danger">Logout</Button></div>
            <div className="mainContainer">
                <div className="d-flex align-items-center justify-content-center gap-5">
                    <div className="border border-black p-3 rounded-4">
                        <div>
                            <Button component="label" variant="contained">
                                Upload Client Side Code (React)
                                <VisuallyHiddenInput onChange={changeHandler} name="clientFile" type="file"
                                                     accept=".zip"/>
                            </Button>
                            <div className="mt-2 text-center">{data.clientFile.name}</div>
                        </div>
                        {response.clientResponse.length > 0 && <div
                            className="bg-white shadow rounded-3 p-3 m-3 d-flex flex-column"
                            style={{minWidth: "420px"}}>
                            <ul className="sessions">
                                {response.clientResponse.map((item, index) =>
                                    <li key={index} className="sessionItems">
                                        <h6 className="fw-semibold mb-0">{item.split("-")[0]}</h6>
                                        <div>{item.split("-")[1]}</div>
                                    </li>
                                )}
                            </ul>
                        </div>}
                    </div>
                    <div className="border border-black p-3 rounded-4">
                        <div>
                            <Button component="label" variant="contained">
                                Upload Server Side Code
                                <VisuallyHiddenInput onChange={changeHandler} name="serverFile" type="file"
                                                     accept=".zip"/>
                            </Button>
                            <div className="mt-2 text-center">{data.serverFile.name}</div>
                        </div>
                        {response.serverResponse.length > 0 && <div
                            className="bg-white shadow rounded-3 p-3 m-3 d-flex flex-column"
                            style={{minWidth: "420px"}}>
                            <ul className="sessions">
                                {response.serverResponse.map((item, index) =>
                                    <li key={index} className="sessionItems">
                                        <h6 className="fw-semibold mb-0">{item.split("-")[0]}</h6>
                                        <div>{item.split("-")[1]}</div>
                                    </li>
                                )}
                            </ul>
                        </div>}
                    </div>
                </div>
                <Spinner color='#00c292' loading={IsSpinner}/>
            </div>
        </>
    )
}

export default Home
