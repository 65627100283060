import {Navigate, Outlet} from "react-router-dom";

const useAuth = () => {
    return localStorage.getItem("loggedIn");
};

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet/> : <Navigate to="/"/>;
};

export default ProtectedRoutes;
