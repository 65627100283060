import React, {useState} from "react";
import classes from "./login.module.css";
import axios from "axios";
import Spinner from "react-spinners/BeatLoader";
import {useNavigate} from "react-router-dom";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";

const Login = () => {
    const navigate = useNavigate();
    const [IsSpinner, setIsSpinner] = useState(false);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const [values, setValues] = useState({
        email: "",
        password: ""
    });
    const inputs = [
        {
            id: 0,
            name: "email",
            placeholder: "Email",
            label: "Email",
            required: true,
        },
        {
            id: 1,
            name: "password",
            type: show ? 'text' : 'password',
            placeholder: "Password",
            label: "Password",
            required: true,
        },
    ];
    const onChange = (e) => {
        setValues(prevState => ({...prevState, [e.target.name]: e.target.value}))
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSpinner(true);
        if (values.email === "deployment" && values.password === "5K[jDsfyK%I2Ys6") {
            login({
                email: values.email,
                password: values.password
            });
        }
    };
    const login = (objToBeSend) => {
        setIsSpinner(false)
        localStorage.setItem('loggedIn', JSON.stringify(objToBeSend))
        setValues({
            email: "",
            password: "",
        });
        navigate("/home");
    }

    return (
        <div className={classes.main}>
            <div className={classes.innerMain}>
                <form className={classes.form}>
                    <h1 className="text-center">Log in to your account.</h1>
                    {inputs.map((input) => (
                        <TextField
                            key={input.id}
                            size="small" type={input.type} className="w-100 mb-3" onChange={onChange}
                            name={input.name}
                            label={input.label}
                            value={values[input.name]}/>
                    ))}
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <Spinner color='#00c292' loading={IsSpinner}/>
                        <FormControlLabel control={<Checkbox onChange={(e) => setShow(e.target.checked)}/>}
                                          label="Show Passwords"/>
                        <Button onClick={handleSubmit}> Submit </Button>
                    </div>
                    {error && <div>{error}</div>}
                </form>
            </div>
        </div>
    );
};

export default Login;
