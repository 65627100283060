import React from 'react'
import MainRouter from "./Main-Router";

function Controller() {
    return (
        <MainRouter/>
    )
}

export default Controller
